import { useTranslate } from '/machinery/I18n'
import { Splash } from '/features/pageOnly/Splash'
import { Icon } from '/features/buildingBlocks/Icon'
import { ContainerXl } from '/features/buildingBlocks/Container'

import styles from './NotFoundContent.css'

import arrowRight from '/images/icons/arrow-right.raw.svg'
import map from '/images/kaart.raw.svg'

export function NotFoundContent({ layoutClassName, image, link }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Splash layoutClassName={styles.splashLayout} {...{ image }}>
        <ContainerXl>
          <div className={styles.container}>
            <div className={styles.grid}>
              <Content layoutClassName={styles.contentLayout} {...{ link }} />
              <Map layoutClassName={styles.mapLayout} {...{ map, link }} />
            </div>
          </div>
        </ContainerXl>
      </Splash>
    </div>
  )
}

function Map({ map, link, layoutClassName }) {
  return (
    <a href={link} data-x='link-to-home' draggable='false' className={layoutClassName}>
      <Icon icon={map} />
    </a>
  )
}

function Content({ link, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      <h1 className={styles.heading}>{__`not-found-title`}</h1>
      <p className={styles.paragraph}>{__`not-found-description`}</p>
      <a href={link} data-x='link-to-home' draggable='false' className={styles.arrowLink}>
        <Icon icon={arrowRight} layoutClassName={styles.iconLayout} />
      </a>
    </div>
  )
}
